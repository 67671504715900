import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import Login from "../components/Login"
import Status from "../components/Status"
import Confirmation from "../components/Confirmation"
import { VISITOR_DONATION } from "../utils/constants"

const Confirm = (props) => {

  return (
    <Layout>
      <Status {...props} menuoptions={{ logout: false,
                                      close: false,
                                      returnToDonation: false,
                                      visitorType: VISITOR_DONATION
                                    }} />
      <Router>
        <Confirmation path="/confirm" component={Confirmation}/>
        <Login path="/app/login"/>
      </Router>
    </Layout>
  )
}

export default Confirm
