import React, { useEffect, useState } from "react"
import View from "../View"
import { handleLogin, isLoggedIn, requestPwdReset } from "../../utils/auth"
import { navigate } from "@reach/router"
import * as styles from "./form.module.css"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from "gatsby"
import * as Yup from "yup"
import withLocation from "../withLocation"

const Index = ({ search }) => {
  const [redirectPath, setRedirectPath] = useState("/app/profile")
  const [forgotPwd, setForgotPwd] = useState(false)

  useEffect(() => {
    if (search) {
      if (search.source) {
        setRedirectPath(search.source);
      }
    }
  }, [search])

  useEffect(() => {
    if (isLoggedIn()) {
      navigate(redirectPath)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    if (isLoggedIn()) {
      return (<div>Du är redan inloggad. Du skickas nu vidare ...
        <div className="spinner-border" role="status">
          <span className="sr-only">Omdirigering ...</span>
        </div></div>)
    } else {
      if (forgotPwd) {
        return (
        <div className="ml-3 mr-3">
          <div className={["container-fluid"].join(" ")}>
            <div className={["row", "justify-content-start", styles.container].join(" ")}>
              <View title="Glömt lösenord">
                <p className={styles[`form__instructions`]}>
                  Fyll i ditt användarnamn eller epostadress och klicka sedan på knappen.
                  Ett epostmeddelande kommer att skickas till dig med instruktioner om
                  hur du återställer ditt lösenord.</p>
                <Formik initialValues={{
                  username: ""
                }}
                        validationSchema={Yup.object().shape({
                          username: Yup.string()
                            .required('Du måste ange ditt användarnamn')
                        })}
                        onSubmit={(values, { setSubmitting, setErrors }) => {
                          requestPwdReset(values, () => {
                            setSubmitting(false);
                            setErrors({ username: "Begäran om återställning har skickats" });
                          });
                        }}>
                  {({ handleSubmit, errors, touched, isValidating, isSubmitting }) => (
                    <Form onSubmit={handleSubmit} className={["text-left", "font-weight-bold"].join(" ")}>
                      <div className="form-group">
                        <label htmlFor="username">Användarnamn eller e-postadress</label>
                        <Field type="text" name="username"
                               className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')}/>
                        <ErrorMessage name="username" component="div" className="invalid-feedback"/>
                      </div>
                      <div>
                        <button className={["btn", "btn-primary"].join(" ")} type="submit"
                                disabled={isSubmitting}>Återställ mitt lösenord
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
                <div style={{ marginTop: "10px" }}>
                  <button className={styles.linkButton} onClick={() => {
                    setForgotPwd(false);
                  }}>Tillbaka till inloggningsformuläret</button>
                </div>
              </View>
            </div>
          </div>
        </div>
        )
      } else {
        return (
          <div className="ml-3 mr-3">
            <div className={["container-fluid"].join(" ")}>
              <div className={["row", "justify-content-start", styles.container].join(" ")}>
                <View title="Inloggning">
                  <p className={styles[`form__instructions`]}>
                    Logga in i MediaLinq och auktorisera din enhet för att sedan kunna donera/betala med den gröna
                    knappen med endast två klick.</p>
                  <p>Om det är första gången du är här måste du <Link to="/app/register">skapa ett konto på MediaLinq</Link>.</p>
                  <p><a target="_blank" rel="noopener noreferrer" href={"https://netmediasystems.se/mottagarlista"}>Se vilka som har den gröna knappen eller MediaLinq-länken.</a></p>

                  <Formik initialValues={{
                    username: "",
                    pwd: ""
                  }}
                          validationSchema={Yup.object().shape({
                            username: Yup.string()
                              .required('Fel användarnamn'),
                            pwd: Yup.string()
                              .min(6, 'Felaktigt lösenord')
                              .required('Felaktigt lösenord')
                          })}
                          onSubmit={(values, { setSubmitting, setErrors }) => {
                            handleLogin(values, redirectPath, () => {
                              setSubmitting(false);
                              setErrors({ pwd: "Fel användarnamn eller lösenord" });
                            })
                          }}>
                    {({ handleSubmit, errors, touched, isValidating, isSubmitting }) => (
                      <Form onSubmit={handleSubmit} className={["text-left", "font-weight-bold"].join(" ")}>
                        <div className="form-group">
                          <label htmlFor="username">Användarnamn eller e-postadress</label>
                          <Field type="text" name="username"
                                 className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')}/>
                          <ErrorMessage name="username" component="div" className="invalid-feedback"/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="pwd">Lösenord</label>
                          <Field type="password" name="pwd"
                                 className={'form-control' + (errors.pwd && touched.pwd ? ' is-invalid' : '')}/>
                          <ErrorMessage name="pwd" component="div" className="invalid-feedback"/>
                        </div>

                        <div style={{ fontWeight: "normal" }}>
                        <div style={{ marginTop: "10px" }}>
                          <button type="button" className={styles.linkButton} onClick={() => {
                            setForgotPwd(true);
                          }}>Har du glömt lösenordet?</button>
                        </div>
                        <p className="float-right">
                          Om detta är en publik dator t.ex. en dator på ett bibliotek
                          eller en dator på jobbet måste du logga ut när du lämnar datorn.
                          Mer information och anvisningar <a href={"https://netmediasystems.se/medialinq"}>hittar du här</a>.

                        </p>
                        </div>
                        <div>
                          <button className={["btn", "btn-primary"].join(" ")} type="submit"
                                  disabled={isSubmitting}>Logga in
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>

                </View>
              </div>
            </div>
          </div>
        )
      }
    }

}

export default withLocation(Index)
