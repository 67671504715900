import React, { useEffect, useState } from "react"

import { api_base_url, browserstring, getCurrentUser, logout, setLoggedInUser } from "../utils/auth"
import { navigate } from "gatsby"
import useStateWithPromise from "../hooks/useStateWithPromise"
import {USER_DONATION_RECIPIENT, USER_PURCHASE_RECIPIENT, USER_SUBSCRIPTION_RECIPIENT} from "../utils/constants";

const axios = require("axios").default
const _ = require('lodash');

/*
Redirect user to login page
 */
const redirectToLogin = function(props) {
  // If we’re not logged in, redirect to the home page.
  const redirectParam = encodeURIComponent(props.location.pathname + props.location.search)
  //navigate(`/app/login?source=`+redirectParam);
  logout()
  navigate(`/app/login?source=` + redirectParam);
}

const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
}

const withUserData = (ComponentToWrap, requireLogin=true) => props => {
  const { ol } = props.search
  let currentUser = getCurrentUser()
  const [userEmail, setUserEmail] = useState("");
  const [userData, setUserData] = useStateWithPromise(null);

  const getVisitorType = () => {
    if (ol && ol === "s") {
      return USER_SUBSCRIPTION_RECIPIENT;
    } else if (ol && ol === "p") {
      return USER_PURCHASE_RECIPIENT;
    } else {
      return USER_DONATION_RECIPIENT;
    }
  }

  const getLoggedInUserData = async () => {
    currentUser = getCurrentUser();
    console.log("CURERNT USER IS");
    console.log(currentUser);
    if (isEmpty(currentUser)) {
      throw new Error("User not logged in");
    }
    const options = {
      method: "post",
      url: `${api_base_url()}/${currentUser.cookie}/profile`,
      data: {
        BrowserData: browserstring(),
      },
    }
    return axios(options);
  }

  useEffect(() => {

    async function checkLogin() {
      if (_.isEmpty(currentUser)) {
        if (requireLogin) {
          redirectToLogin(props);
        } else {
          await setUserData({});
          setUserEmail("");
        }
      } else {
        getLoggedInUserData()
          .then(async response => {
            setUserEmail(response.data.Profile.UserEmail);
            console.log("SET USER DATA TO:");
            console.log(response.data);
            await setUserData(response.data);
          })
          .catch(async error => {
            console.error("EROROR");
            console.log(error);
            // User seems to be logged in even though no user exists with the given username and email
            // Clean up local storage by logout
            logout();
            if (requireLogin) {
              redirectToLogin(props);
            } else {
              await setUserData({});
              setUserEmail("");
            }
          })
      }
    }
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // Expose this method as loginFunc
  // This method logs in the user
  // If login is is successful user information is stored in the browser and
  // success_Callback is called with username and session id (cookieid) as parameters.
  // If login is unsuccessful, then
  const handleUserLogin = (username, password, success_callback, error_callback) => {
    axios({
      method: 'post',
      url: `${api_base_url()}/login`,
      data: {
        username: username.toLowerCase(),
        password: password,
        browser_data: browserstring()
      }
    }).then(function(response) {
      setLoggedInUser({
        username: response.data.username,
        cookie: response.data.cookieid
      });

      setUserData(response.data).then(r =>
        success_callback(response.data.username, response.data.cookieid)
      );
    })
      .catch(e => {
        error_callback();
      });
  }

  // Returns:
  // -1 = User is not logged in
  // 0 = User is not confirmed
  // 1 = User is confirmed and active
  // 2 = User is inactivated
  const checkUserStatus = async () => {

    try {
      const response = await getLoggedInUserData();
      const userdata = response.data;
      if (_.isEmpty(userdata)) {
        return -1;
      } else {
        if (userdata.Profile.Verified === "True") {
          return 1;
        } else if (userdata.Profile.Verified === "Inactive") {
          return 2;
        } else {
          return 0;
        }
      }
    } catch (error) {
      return -1;
    }

  }


  // Expose this method as registrationFunc
  // Registers a new user with the new simplified
  // registration flow. Only requires email address
  // as input. Returns the userdata for the newly
  // created user. If user is already logged in
  // this method returns userdata for the logged
  // in user.
  const registerNewUser = async (email, selectedUsername="") => {

    if (!selectedUsername) {
      selectedUsername = email;
    }

    let ref = "";
    if (props.search) {
      if ("origin" in props.search)
        ref = props.search.origin;
      else if ("uid" in props.search)
        ref = props.search.uid;
    }

    if(_.isEmpty(userData)) {
      const options = {
        method: 'post',
        url: `${api_base_url()}/register`,
        data: {
          email: email.toLowerCase().trim(),
          selected_username: selectedUsername,
          BrowserData: browserstring(),
          ref: ref
        }
      };

      let reg_resp = await axios(options);

      setLoggedInUser({
        username: reg_resp.data.username,
        cookie: reg_resp.data.cookieid
      });

      const login_resp = await getLoggedInUserData();
      setUserEmail(login_resp.data.Profile.UserEmail);
      await setUserData(login_resp.data);
      return(login_resp.data);
    } else {
      return userData;
    }
  }

  // Expose this method as reloadUserFunc
  const reloadUserData = async () => {
    console.log("HJEEELLLOOO");
    getLoggedInUserData()
      .then(async response => {
        setUserEmail(response.data.Profile.UserEmail);
        await setUserData(response.data);
        console.log("RETURNING:");
        console.log(response);
        return (response.data);
      })
      .catch(async error => {
        // User seems to be logged in even though no user exists with the given username and email
        // Clean up local storage by logout
        console.log("ERROR:");
        console.log(error);
        logout();
        await setUserData({});
        setUserEmail("");
        return({});
      })
  }


  if (userData == null) {
    return (<div>Laddar din profil...
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div></div>)
  } else {
    return (
      <ComponentToWrap
        {...props}
        userEmail={userEmail}
        userData={userData}
        registrationFunc={registerNewUser}
        loginFunc={handleUserLogin}
        reloadUserFunc={reloadUserData}
        checkUserStatusFunc={checkUserStatus}
        getVisitorTypeFunc={getVisitorType}
      />
    )
  }
}

export default withUserData